import React, { useEffect } from "react"
import BlogPage1 from "../../components/v1/pages/blog";
import { navigate } from "gatsby";
import { RouteLinks } from "../../utils/route-links";

const BlogPage = () => {
  if (process.env.GATSBY_VERSION === "v1") {
    return <BlogPage1 />;
  } else {
    useEffect(() => {
      navigate(RouteLinks["404"]);
    }, []);
    
    return null;
  }
};

export default BlogPage;
